<template>
    <el-drawer
        size="85%"
        :append-to-body="true"
        :visible.sync="showFlag"
        custom-class="drawer-100"
        :wrapperClosable="false"
        :destroy-on-close="true"
    >
        <div slot="title" class="d-title">
            <el-page-header @back="goBack" :content="isEdit ? '编辑会员卡' : '新增会员卡'"></el-page-header>
        </div>
        <el-container class="d-body">
            <el-main>
                <div class="m-div">
                    <el-form ref="form" :model="form" class="form" label-width="110px" :rules="rules">
                        <div class="card-type df ai">
                            <div class="ct-title">会员卡类型</div>
                            <div class="ct-tab df ai">
                                <div class="tab-item" :class="{'active': form.type === 1}" @click="tabClick(1)">期限卡</div>
                                <div class="tab-item" :class="{'active': form.type === 2}" @click="tabClick(2)">次卡</div>
                                <div class="tab-item" :class="{'active': form.type === 3}" @click="tabClick(3)">储值卡</div>
                            </div>
                        </div>
                        <div class="info-div">
                            <div class="info-title df ai">
                                <img src="@/assets/images/card-icon1.png" alt="" width="24px" srcset="">
                                <div>基础信息</div>
                            </div>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="发行商户" prop="merchantId">
                                        <el-select v-model="form.merchantId" class="w-full" placeholder="请选择发行商户" disabled>
                                            <el-option
                                            v-for="item in merchantBox"
                                            :key="item.merchantId"
                                            :label="item.merchantName"
                                            :value="item.merchantId"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="会员卡名称" prop="name">
                                        <el-input v-model="form.name" :maxlength="20" placeholder="请输入会员卡名称(20字符以内)"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="激活方式" prop="activeType">
                                        <el-select v-model="form.activeType" class="w-full" placeholder="请选择激活方式">
                                            <el-option label="自动激活" :value="1"></el-option>
                                            <el-option label="现场激活" :value="2"></el-option>
                                            <el-option label="首次使用激活" :value="3"></el-option>
                                            <el-option label="指定日期激活" :value="4"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="info-div">
                            <div class="info-title df ai">
                                <img src="@/assets/images/card-icon2.png" alt="" width="24px" srcset="">
                                <div>权益信息</div>
                            </div>
                            <el-row>
                                <el-col v-if="form.type === 2" :span="24">
                                    <el-form-item label="可用次数" prop="useLimitQty">
                                        <el-input-number
                                            v-model="form.useLimitQty"
                                            controls-position="right"
                                            step-strictly
                                            :precision="0"
                                            :min="1"
                                            :max="99999"
                                            placeholder="请输入可用次数"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="form.type === 3">
                                    <el-form-item label="最低储值金额" prop="minMoneyLimit">
                                        <el-input-number
                                            v-model="form.minMoneyLimit"
                                            controls-position="right"
                                            :precision="2"
                                            :min="0.01"
                                            :max="99999"
                                            class="w-full"
                                            placeholder="请输入最低储值金额"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="form.type === 3">
                                    <el-form-item label="金额可用业务" prop="availableTo">
                                        <el-checkbox-group v-model="form.availableTo">
                                            <el-checkbox :label="1">场地租赁</el-checkbox>
                                            <el-checkbox :label="2">商品购买</el-checkbox>
                                            <el-checkbox :label="3">课程购买</el-checkbox>
                                            <el-checkbox :label="4">器材租赁</el-checkbox>
                                            <el-checkbox :label="5">损坏赔偿</el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="form.type === 1" :span="24">
                                    <el-form-item label="适用运动项目" prop="sportsDef">
                                        <el-checkbox-group v-model="form.sportsDef">
                                            <el-checkbox v-for="item in sportList" :label="item.id" :key="item.id">{{ item.label }}</el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="form.type === 2" :span="24">
                                    <el-form-item label="适用运动项目" prop="sportDef">
                                        <el-radio-group v-model="form.sportDef">
                                            <el-radio v-for="(item, index) in sportList" :label="item.id" :key="item.id + '_' + index">{{ item.label }}</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="可通行场区" prop="regionDef">
                                        <el-select v-model="form.regionDef" class="w-full" multiple placeholder="请选择该会员卡可同行场区(可多选)">
                                            <el-option
                                            v-for="item in venueBox"
                                            :key="item.id"
                                            :label="item.venueName"
                                            :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="form.type !== 3" :span="12">
                                    <el-form-item label="有效期限" prop="term">
                                        <div class="df ai">
                                            <el-input-number
                                                v-model="form.term"
                                                controls-position="right"
                                                step-strictly
                                                :precision="0"
                                                :min="1"
                                                :max="99999"
                                                class="f-cell"
                                                style="width:50%"
                                                placeholder="请输入有效期限(不填则永久有效)"
                                            ></el-input-number>
                                            <!-- <div class="margin-left-10">天</div> -->
                                            <el-select v-model="form.termCode" style="margin-left:20px">
                                                <el-option label="天" value="D"></el-option>
                                                <el-option label="月" value="M"></el-option>
                                                <el-option label="年" value="Y"></el-option>
                                            </el-select>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="form.type === 3" :span="12">
                                    <el-form-item label="提前预约天数" prop="cutOffBufferQty">
                                        <div class="df ai">
                                            <el-input-number
                                                v-model="form.cutOffBufferQty"
                                                controls-position="right"
                                                step-strictly
                                                :precision="0"
                                                :min="0"
                                                :max="99999"
                                                class="f-cell"
                                                placeholder="请输入可提前预约天数"
                                            ></el-input-number>
                                            <div class="margin-left-10">天</div>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="是否可退卡" prop="availableExitMark">
                                        <el-switch
                                        v-model="form.availableExitMark"
                                        :active-value="1"
                                        :inactive-value="2"
                                        active-text="开"
                                        inactive-text="关">
                                        </el-switch>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="是否可转卡" prop="isTransfer">
                                        <div class="df ai fw">
                                            <div class="margin-right-30">
                                                <el-switch
                                                v-model="form.isTransfer"
                                                active-text="开"
                                                inactive-text="关">
                                                </el-switch>
                                            </div>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="是否可多次转卡" prop="transferLimitQty" v-if="form.isTransfer">
                                        <div>
                                            <el-switch
                                            v-model="form.transferLimitQty"
                                            active-text="开"
                                            inactive-text="关">
                                            </el-switch>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="是否可共享" prop="shareLimit">
                                                <div class="df ai fw">
                                                    <div class="margin-right-30">
                                                        <el-switch
                                                        v-model="form.shareLimit"
                                                        active-text="开"
                                                        inactive-text="关">
                                                        </el-switch>
                                                    </div>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="可用人数" prop="shareLimitQty" v-if="form.shareLimit">
                                                    <div>
                                                        <el-input-number
                                                        v-model="form.shareLimitQty"
                                                        controls-position="right"
                                                        style="width: 100%"
                                                        step-strictly
                                                        :precision="0"
                                                        :min="1"
                                                        :max="100"
                                                        class="f-cell"
                                                        placeholder="包括卡主"
                                                    ></el-input-number>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-col>
                                <el-col :span="12" v-if="this.form.type !== 3">
                                    <el-row>
                                        <el-col :span="8">
                                            <el-form-item label="是否可停卡" prop="pauseLimit">
                                                <div class="df ai fw">
                                                    <div class="margin-right-30">
                                                        <el-switch
                                                        v-model="form.pauseLimit"
                                                        :active-value="true"
                                                        :inactive-value="false"
                                                        active-text="开"
                                                        inactive-text="关">
                                                        </el-switch>
                                                    </div>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label-width="80px" label="可停次数" prop="pauseLimitQty" v-if="form.pauseLimit">
                                                <div>
                                                    <el-input-number
                                                        v-model="form.pauseLimitQty"
                                                        controls-position="right"
                                                        step-strictly
                                                        style="width: 100%"
                                                        :precision="0"
                                                        :min="1"
                                                        :max="100"
                                                        class="f-cell margin-right-10"
                                                        placeholder="可停次数"
                                                    ></el-input-number>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label-width="100px" label="每次停卡天数" prop="pauseDayLimitQty" v-if="form.pauseLimit">
                                                <div>
                                                    <el-input-number
                                                        v-model="form.pauseDayLimitQty"
                                                        controls-position="right"
                                                        step-strictly
                                                        style="width: 100%"
                                                        :precision="0"
                                                        :min="1"
                                                        :max="100"
                                                        class="f-cell"
                                                        placeholder="每次停卡天数"
                                                    ></el-input-number>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="时段限制" prop="period">
                                        <div class="df ai fw">
                                            <div>
                                                <el-switch
                                                v-model="form.period"
                                                active-text="开"
                                                inactive-text="关">
                                                </el-switch>
                                            </div>
                                            <div v-if="form.period" class="margin-left-30">
                                                <el-button type="text" @click="openAddTime()">添加可用入场时段</el-button>
                                            </div>
                                            <div class="weekBox">
                                                <div v-for="(item, index) in form.periodDef" :key="index" class="week">
                                                    <div>{{getweek(item.day)}}</div>
                                                    <div>{{item.start}}~{{item.end}}</div>
                                                    <div class="del" @click="del(index)">删除</div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="权益说明" prop="description">
                                        <div class="edit_container">
                                            <quill-edit ref="quilldescription" id="description" :detail="form.description" @change="onEditChangedescription" :qnLocation="apis.ImageUpload">
                                            </quill-edit>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="info-div">
                            <div class="info-title df ai">
                                <img src="@/assets/images/card-icon3.png" alt="" width="24px" srcset="">
                                <div>销售信息</div>
                            </div>
                            <el-row>
                                <el-col  v-if="form.type !== 3" :span="12">
                                    <el-form-item label="默认售价" prop="defaultPrice">
                                         <div class="df ai">
                                            <el-input-number
                                                v-model="form.defaultPrice"
                                                controls-position="right"
                                                :precision="2"
                                                :min="0.01"
                                                :max="99999"
                                                class="f-cell"
                                                placeholder="请输入默认售价"
                                            ></el-input-number>
                                            <div class="margin-left-10">元</div>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="封面图片" prop="cardImgPath">
                                        <el-upload
                                        class="upload"
                                        :show-file-list="false"
                                        :with-credentials="true"
                                        :action="apis.ImageUpload"
                                        :httpRequest="uploadHttpDefault"
                                        name="file"
                                        accept=".png,.jpg"
                                        :on-success="uploadSuccess"
                                        :on-error="uploadError">
                                            <img v-if="form.cardImgPath" class="image-upload" :src="form.cardImgPath" alt="" srcset="" width="78" height="78">
                                            <img v-else class="image-upload" src="@/assets/images/upload_image.png" alt="" srcset="">
                                            <div class="upload-images-tip">
                                            支持JPG、BMP、PNG格式，大小2MB以下，用于微信端展示
                                            </div>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="会员卡简介" prop="introduce">
                                        <div class="edit_container">
                                            <quill-edit ref="quillintroduce" id="introduce" :detail="form.introduce" @change="onEditChangeintroduce" :qnLocation="apis.ImageUpload">
                                            </quill-edit>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="购卡协议" prop="agreement">
                                        <div class="edit_container">
                                            <quill-edit ref="quillagreement" id="agreement" :detail="form.agreement" @change="onEditChangeagreement" :qnLocation="apis.ImageUpload">
                                            </quill-edit>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form>
                </div>
            </el-main>
            <el-footer>
                <el-button class="footer-confirm" @click="save">{{$t('sure')}}</el-button>
                <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
            </el-footer>
        </el-container>
        <add-time-dialog ref="addTimeDialog" @save="saveTime" @refreshTime="getTime"></add-time-dialog>
    </el-drawer>
</template>
<script>
import apis from "@/apis";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import quillEdit from '@/components/quillEdit.vue'
import addTimeDialog from './addTimeDialog.vue'
import mixin from "@/mixins/dialogMixin";
import { mapState } from "vuex";

export default {
    components: {
        quillEdit,
        addTimeDialog,
    },
    mixins: [mixin],
    props: {
        isEdit: {
            required: true,
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            showFlag: false,
            form: {
                type: 1, // 1期限卡 2次卡 3储值卡
                merchantId: '', // 发行商户
                name: '', // 会员卡名称
                activeType: '', // 激活方式
                useLimitQty: '', // 可用次数
                minMoneyLimit: '', // 最低储值金额
                availableTo: [], // 金额可用业务
                sportsDef: [], // 适用运动项目 周期卡
                sportDef: '', // 适用运动项目 次卡
                regionDef: [], // 可同行场区
                term: '', // 有效期限
                termCode: 'D', // 天、月、年
                cutOffBufferQty: '', // 提前预约天数
                availableExitMark: 2, // 是否可退卡
                isTransfer: false, // 是否可转卡
                transferLimitQty: false, // 是否多次转卡
                // transferLimitQty: '', // 转卡次数
                shareLimit: false, // 是否可共享
                shareLimitQty: '', // 可用人数
                pauseLimit: false, // 是否可停卡
                pauseLimitQty: '', // 可停次数
                pauseDayLimitQty: '', // 每次可停天数
                period: false, // 时段限制
                periodDef: [], // 时段定义
                description: '', // 权益说明
                defaultPrice: '', // 默认售价
                cardImgPath: '', // 封面图片
                introduce: '', // 会员简介
                agreement: '', // 购卡协议
                timeList: [],
            },
            rules: {
               merchantId: [{ required: true, message: '请选择发行商户', trigger: ['blur', 'change'] }],
               name: [{ required: true, message: '请输入会员卡名称', trigger: ['blur', 'change'] }],
               activeType: [{ required: true, message: '请选择激活方式', trigger: ['blur', 'change'] }],
               useLimitQty: [{ required: true, message: '请输入可用次数', trigger: ['blur', 'change'] }],
               minMoneyLimit: [{ required: true, message: '请输入最低储值金额', trigger: ['blur', 'change'] }],
               availableTo: [{ required: true, message: '请选择金额可用业务', trigger: ['blur', 'change'] }],
               sportsDef: [{ required: true, message: '请选择适用运动项目', trigger: ['blur', 'change'] }],
               sportDef: [{ required: true, message: '请选择适用运动项目', trigger: ['blur', 'change'] }],
               regionDef: [{ required: true, message: '请选择可通行场区', trigger: ['blur', 'change'] }],
               term: [{ required: true, message: '请输入有效期限', trigger: ['blur', 'change'] }],
               cardImgPath: [{ required: true, message: '请上传封面图片', trigger: ['blur', 'change'] }],
               introduce: [{ required: true, message: '请输入会员卡简介', trigger: ['blur', 'change'] }],
               agreement: [{ required: true, message: '请输入购卡协议', trigger: ['blur', 'change'] }],
            },
            sportList: [], // 运动项目
            merchantBox: [], // 商户下拉框
            venueBox: [], // 通行场区下拉框
            week: [
                { value: "1", text: "周一" },
                { value: "2", text: "周二" },
                { value: "3", text: "周三" },
                { value: "4", text: "周四" },
                { value: "5", text: "周五" },
                { value: "6", text: "周六" },
                { value: "7", text: "周日" },
            ],
            apis,
        };
    },
    computed: {
        ...mapState(['DROPDOWNBOX', "TOKEN", "venueId", "venueList", 'merchantAccountDetailInfoDTO']),
    },
    methods: {
        getbox() {
            // 发行商户下拉框
            this.$http.get(apis.findAllMerchant).then((res) => {
                if (res.data.code === 0) {
                    this.merchantBox = res.data.data
                    this.merchantBox.forEach((item) => {
                        if (item.merchantId === this.merchantAccountDetailInfoDTO.merchantId) {
                            this.form.merchantId = item.merchantId
                        }
                    });
                }
            })
            // 运动项目下拉框
            this.$http.get(apis.dictGetSports).then((res) => {
                if (res.data.code === 0) {
                    this.sportList = res.data.data.SPORT_PROJECT
                }
            })
            // 获取通行场区下拉取值
            this.$http.get(`${apis.getVenueListByMerchantId}?merchantId=${this.merchantAccountDetailInfoDTO.merchantId}`).then((res) => {
                if (res.data.code === 0) {
                    this.venueBox = res.data.data
                }
            })
        },
        init() {
            if (this.$refs.form) {
                this.$refs.form.resetFields()
            }
            this.form.periodDef = []
            this.form.termCode = 'D'
        },
        // tab切换点击
        tabClick(type) {
            if (this.form.type !== type) {
                this.form.type = type
                this.$refs.form.clearValidate()
            }
        },
        // 弹框打开触发
        showDrawer() {
            this.init()
            this.form = {
                type: 1, // 1期限卡 2次卡 3储值卡
                merchantId: '', // 发行商户
                name: '', // 会员卡名称
                activeType: '', // 激活方式
                useLimitQty: '', // 可用次数
                minMoneyLimit: '', // 最低储值金额
                availableTo: [], // 金额可用业务
                sportsDef: [], // 适用运动项目 周期卡
                sportDef: '', // 适用运动项目 次卡
                regionDef: [], // 可同行场区
                term: '', // 有效期限
                termCode: 'D', // 天、月、年
                cutOffBufferQty: '', // 提前预约天数
                availableExitMark: 2, // 是否可退卡
                isTransfer: false, // 是否可转卡
                transferLimitQty: false, // 是否多次转卡
                shareLimit: false, // 是否可共享
                shareLimitQty: '', // 可用人数
                pauseLimit: false, // 是否可停卡
                pauseLimitQty: '', // 可停次数
                pauseDayLimitQty: '', // 每次可停天数
                period: false, // 时段限制
                periodDef: [], // 时段定义
                description: '', // 权益说明
                defaultPrice: '', // 默认售价
                cardImgPath: '', // 封面图片
                introduce: '', // 会员简介
                agreement: '', // 购卡协议
                timeList: [],
            }
            this.showFlag = true
            this.getbox()
        },
        goBack() {
            this.showFlag = false
        },
        // 权益说明
        onEditChangedescription(value) {
            this.form.description = value
            this.$refs.form.validateField("description");
        },
        // 会员卡简介
        onEditChangeintroduce(value) {
            this.form.introduce = value
            this.$refs.form.validateField("introduce");
        },
        // 购卡协议
        onEditChangeagreement(value) {
            this.form.agreement = value
            this.$refs.form.validateField("agreement");
        },
        removeImage(index) {
            this.form.filesPicturesPath.splice(index, 1)
            this.form.files.splice(index, 1)
        },
        uploadSuccess(res) {
            console.log(res)
            this.form.cardImgPath = res.data.url
            this.$refs.form.validateField("cardImgPath");
        },
        uploadError(res) {
            console.log(res)
        },
        // 时段添加
        openAddTime() {
            let otherArr = []
            if (this.form.periodDef.length > 0) {
                this.form.periodDef.forEach((item) => {
                    otherArr = otherArr.concat(item.day)
                })
            }
            // const otherArr = ['2', '5']
            this.$refs.addTimeDialog.showDialog(otherArr)
        },
        // 删除时间段
        del(index) {
            this.form.periodDef.splice(index, 1)
        },
        // 更新时段时间
        getTime(data) {
            if (data.index === -1) {
                this.form.timeList.push(data.data)
            } else {
                this.form.timeList.splice(data.index, 1, data.data)
            }

        },
        // 获取时间限制数据
        saveTime(data) {
            this.form.periodDef.push({ ...data })
        },
        // 获取星期方法
        getweek(data) {
            const arr = []
            this.week.forEach((item) => {
                data.forEach((el) => {
                    if (el === item.value) {
                        arr.push(item.text)
                    }
                })
            })
            return arr.join(',')
        },
        getDetails(id) {
            this.cardID = id
            this.$http.get(`${apis.cardTypeGetById}?id=${id}`).then((res) => {
                if (res.data.code === 0) {
                    this.showDrawer()
                    const data = res.data.data
                    console.log(data.termConvert.termNum, 'data.termConvert.termNum');
                    this.form = {
                        ...data,
                        ...{
                            periodDef: JSON.parse(data.periodDef),
                            venueDef: data.venueDef.split(','),
                            useLimitQty: data.cardTypeRightEntity.useLimitQty,
                            minMoneyLimit: data.cardTypeRightEntity.minMoneyLimit,
                            availableTo: data.cardTypeRightEntity.availableTo ? data.cardTypeRightEntity.availableTo.split(',').map(Number) : [],
                            sportsDef: data.cardTypeRightEntity.sportsDef.split(','),
                            regionDef: data.regionDef.split(','),
                            term: data.termConvert ? data.termConvert.termNum : '',
                            termCode: data.termConvert ? data.termConvert.termCode : '',
                            cutOffBufferQty: data.cardTypeRightEntity.cutOffBufferQty,
                            availableExitMark: data.cardTypeRightEntity.availableExitMark,
                            //修改
                            isTransfer: data.cardTypeRightEntity.isTransfer,
                            transferLimitQty: data.cardTypeRightEntity.transferLimitQty,
                            shareLimit: data.cardTypeRightEntity.shareLimitQty !== 0,
                            shareLimitQty: data.cardTypeRightEntity.shareLimitQty,
                            pauseLimit: data.cardTypeRightEntity.pauseLimitQty !== 0,
                            pauseLimitQty: data.cardTypeRightEntity.pauseLimitQty,
                            pauseDayLimitQty: data.cardTypeRightEntity.pauseDayLimitQty,
                            period: data.cardTypeRightEntity.periodDef !== '' || data.cardTypeRightEntity.periodDef !== null,
                            description: data.cardTypeRightEntity.description,
                        },
                    }
                    if (this.form.type === 1) {
                        this.form.sportsDef = data.cardTypeRightEntity.sportsDef ? data.cardTypeRightEntity.sportsDef.split(',') : []
                    }
                    if (this.form.type === 2) {
                        // this.form.sportDef = data.cardTypeRightEntity.sportsDef
                        this.$set(this.form, 'sportDef', data.cardTypeRightEntity.sportsDef)
                    }
                    if (this.form.periodDef === 'null' || this.form.periodDef === null) {
                        this.form.periodDef = []
                        this.form.period = false
                    }
                    console.log(this.form, 555)
                } else {
                    this.$message.info(res.data.msg)
                }
            })
        },
        // 确定按钮
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const formData = { ...this.form }
                    console.log(formData, 'formData');
                    // formData.sportsDef = formData.sportsDef.join(',') // 运动项目
                    let flag = false
                    if (this.form.type === 1) {
                        formData.sportsDef = formData.sportsDef.join(',') // 运动项目
                        if (!formData.sportsDef) {
                            flag = true
                        }
                        formData.useLimitQty = ''
                        formData.minMoneyLimit = ''
                        formData.availableTo = []
                        formData.cutOffBufferQty = ''
                    } else if (this.form.type === 2) {
                        formData.sportsDef = this.form.sportDef
                        if (!formData.sportsDef) {
                            flag = true
                        }
                        formData.minMoneyLimit = ''
                        formData.availableTo = []
                        formData.cutOffBufferQty = ''
                    } else {
                        formData.sportsDef = ''
                        formData.useLimitQty = ''
                        formData.pauseLimit = ''
                    }
                    if (flag) {
                        this.$message.warning('适用运动项目不能为空')
                        return
                    }
                    formData.venueDef = formData.regionDef.join(',') // 场区
                    formData.regionDef = formData.regionDef.join(',') // 场区
                    formData.availableTo = formData.availableTo.join(',') // 可用业务
                    if (this.form.type === 3) {
                         formData.term = '99Y'
                    } else {
                        formData.term = `${formData.term}${formData.termCode}` // 有效期
                    }
                    // if (formData.isTransfer && formData.transferLimitQty) { // 转卡
                    //     formData.transferLimitQty = 999
                    // } else if (formData.isTransfer && !formData.transferLimitQty) {
                    //     formData.transferLimitQty = 1
                    // } else {
                    //     formData.transferLimitQty = 0
                    // }
                    if (!formData.shareLimit) {
                        formData.shareLimitQty = 0
                    }
                    if (!formData.pauseLimit) { // 停卡
                        formData.pauseLimitQty = 0
                        formData.pauseDayLimitQty = 0
                    }
                    if (!formData.period) { // 时段
                        formData.periodDef = null
                    } else {
                        formData.periodDef = JSON.stringify(formData.periodDef)
                    }
                    this.$http.post(this.isEdit ? apis.cardTypeupdate : apis.cardTypeSave, formData).then((res) => {
                        if (res.data.code === 0) {
                            this.closeDialog()
                        }
                    })
                }
            })
        },
        closeDialog() {
            this.init()
            this.$emit('closeDialog', false)
            this.$refs.form.resetFields();
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
.d-body{border-top: 1px solid #dddddd; height: 100%;}
.el-main{padding: 0;  border-bottom: 1px solid #dddddd;}
.el-footer{ text-align: center; line-height: 60px;}
.m-div{ padding: 20px 28px;}
.form{
    .card-fm{ height: 120px; width:150px;}
    .weekBox {
        display: block;
        margin-left: 30px;
        .week {
            display: flex;
            .del {
                color: red;
                cursor: pointer;
                margin-left: 10px;
            }
        }
    }
}
.card-type{ margin-bottom: 20px;
    .ct-title{ margin-right: 30px;}
    .tab-item{ width: 82px; height: 28px; line-height: 28px; text-align: center; border-radius: 15px;
    border: 1px solid #D4D4D4; font-size: 14px; color: #999999; margin-right: 14px; cursor: pointer;}
    .tab-item.active{ border: 1px solid #439BFF; color: #ffffff; background: #439BFF;}
}
.pb-20{ padding-bottom: 20px;}
.info-div{ margin-bottom: 10px;
    .info-title{ font-size: 16px;  font-weight: bold; margin: 20px 0; color: #439BFF;
        img{ margin-right: 8px;}
    }
    .image-upload {
        width: 50px;
        height: 50px;
    }
    .upload-images-tip {
        color:rgba(168,168,168,1);
        font-size:12px;
        margin-top: -15px;
    }
    .image-wrap{
        width: 50px;
        height: 50px;
        margin-right: 10px;
        position: relative;
        float: left;
        .images-close {
            position: absolute;
            right: -7px;
            top: -7px;
            width: 14px;
            height: 14px;
            cursor: pointer;
        }
    }
}
.lh-small{ padding-top: 8px; line-height: 24px;}
</style>
<style>
.drawer-100 .el-drawer__body{height: calc(100% - 80px);}
.info-div .el-tabs__nav-wrap::after {
    display: block!important;
    height: 1px;
 }
.info-div .el-tabs__item {
    padding: 0 10% !important;
}
.info-div .el-tag .el-icon-close {
    line-height: 17px !important;
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.edit_container {
    margin-top: -15px;
  .ql-snow {
    .ql-tooltip {
      left: 10px !important;
    }
  }
}
.d-body {
  .ql-editor {
      padding: 0;
    .ql-editor {
      padding: 10px;
      height: 188px;
    }
  }
  .ql-snow .ql-formats {
    line-height: 24px;
  }
}
/deep/.el-input-number .el-input__inner{
  text-align: left!important;
}
</style>
